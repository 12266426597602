export const routes = [
    {
        path: '/inflows',
        name: 'inflows.browse',
        component: () => import(/* webpackChunkName: "InflowsBrowse" */ '@/views/app/InflowsBrowse.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/inflows/create',
        name: 'inflows.create',
        component: () => import(/* webpackChunkName: "InflowsActions" */ '@/views/app/InflowsActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/inflows/:id/edit',
        name: 'inflows.edit',
        component: () => import(/* webpackChunkName: "InflowsActions" */ '@/views/app/InflowsActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/inflows/:id/delete',
        name: 'inflows.delete',
        component: () => import(/* webpackChunkName: "InflowsActions" */ '@/views/app/InflowsActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup" }
    },
]